<template>
  <div>
    <div v-if="isDataLoaded">
      <!--Table-->
      <div class="table-responsive">
        <table class="table b-table table-hover" v-bind:class="{'table-striped':isLoaded}">
          <thead>
          <tr>
            <th class="d-none d-lg-table-cell" v-if="canOperateClients&&  canOperateRep">
              <b-form-checkbox v-model="allRowsSelected" :indeterminate.sync="indeterminateRows"/>
            </th>
            <!--Table header-->
            <template v-if="!optionsEnable">
              <th class="d-none d-lg-table-cell" v-if="activeColumns['id']" @click="sort('id')"
                  :aria-sort="sortStatus('id')">
                <a>{{ msg('ID') }}</a>
              </th>
              <th class="d-none d-lg-table-cell" v-if="activeColumns['count']"
                  :aria-sort="sortStatus('count')">
                <a>{{ msg('Number of bookings') }}</a>
              </th>
              <th class="d-none d-lg-table-cell" v-if="activeColumns['creationtime']" @click="sort('creationtime')"
                  :aria-sort="sortStatus('creationtime')">
                {{ msg('Creationtime') }}
              </th>
              <th class="d-none d-lg-table-cell" v-if="activeColumns['branchId']" @click="sort('branchId')"
                  :aria-sort="sortStatus('branchId')">
                {{ msg('branch') }}
              </th>
              <th class="d-none d-lg-table-cell" v-if="activeColumns['verified']" @click="sort('verified')"
                  :aria-sort="sortStatus('verified')">
                {{ msg('verified') }}
              </th>
              <th class="d-none d-lg-table-cell" v-if="activeColumns['firstname']" @click="sort('firstname')"
                  :aria-sort="sortStatus('firstname')">
                {{ msg('Firstname') }}
              </th>
              <th class="d-none d-lg-table-cell" v-if="activeColumns['lastname']" @click="sort('lastname')"
                  :aria-sort="sortStatus('lastname')">
                {{ msg('lastname') }}
              </th>
              <th class="d-table-cell d-lg-none" v-if="activeColumns['firstname']" @click="sort('firstname')"
                  :aria-sort="sortStatus('firstname')">
                {{ msg('Name') }}
              </th>
              <th class="d-none d-lg-table-cell" v-if="activeColumns['phone']" @click="sort('phone')"
                  :aria-sort="sortStatus('phone')">
                {{ msg('Phone') }}
              </th>
              <th class="d-none d-lg-table-cell" v-if="activeColumns['mobile']" @click="sort('mobile')"
                  :aria-sort="sortStatus('mobile')">
                {{ msg('Mobile') }}
              </th>
              <th class="d-none d-lg-table-cell" v-if="activeColumns['email']" @click="sort('email')"
                  :aria-sort="sortStatus('email')">
                {{ msg('Email') }}
              </th>
              <th class="d-none d-lg-table-cell" v-if="activeColumns['address']" @click="sort('address')"
                  :aria-sort="sortStatus('address')">
                {{ msg('Address') }}
              </th>
              <th class="d-none d-lg-table-cell" v-if="activeColumns['zip']" @click="sort('zip')"
                  :aria-sort="sortStatus('zip')">
                {{ msg('Zip') }}
              </th>
              <th class="d-none d-lg-table-cell" v-if="activeColumns['tag']" @click="sort('tag')"
                  :aria-sort="sortStatus('tag')">
                {{ msg('Tag') }}
              </th>
              <th class="d-none d-lg-table-cell" v-if="activeColumns['country']" @click="sort('country')"
                  :aria-sort="sortStatus('country')">
                {{ msg('City') }},{{ msg('Country') }}
              </th>
              <th v-if="activeColumns['statusid']" @click="sort('statusid')" :aria-sort="sortStatus('statusid')">
                {{ msg('Status') }}
              </th>
              <!--<th class="d-none d-lg-table-cell" v-if="!activeColumns['statusid']">{{msg('Status')}}</th>-->
            </template>
            <!--Options in header-->
            <template v-if="optionsEnable && canEditRep">
              <th class="d-none d-lg-table-cell table-options" colspan="11">
                <b-button id="deleteClient" variant="flat-danger" class="btn-icon rounded-circle"
                          @click="deleteClients">
                  <i class="fas fa-trash"></i>
                </b-button>
                <b-button id="bulkChange" variant="flat-primary" class="btn-icon rounded-circle">
                  <i class="fas fa-exchange-alt"></i>
                </b-button>
                <b-button id="addRepresentative" variant="flat-info" class="btn-icon rounded-circle" v-b-tooltip.hover
                          :title="msg('Add representant')">
                  <i class="fas fa-user-plus"></i>
                </b-button>
                <b-button id="duplicateClients" v-if="selectedRows.length == 1" variant="flat-secondary"
                          class="btn-icon rounded-circle" v-b-tooltip.hover :title="msg('Duplicate')">
                  <i class="far fa-copy"></i>
                </b-button>

                <!--Bulk change popover-->
                <b-popover :show.sync="operations.bulkChange" @show="dismissPopover"
                           size="xl"
                           placement="bottom" target="bulkChange"
                           :title="msg('Bulk Change')">
                  <div class="p-2">
                    <form>
                      <b-form-group
                          :label="msg('Status')"
                          label-for="statusId"
                          label-cols="6"
                          class="mb-1">

                        <b-form-select id="statusId" size="sm"
                                       v-model="bulkChange.statusId">
                          <option value="0">{{ msg('Do not update') }}</option>
                          <option v-for="st in getAllStatus" v-bind:value="st.id"
                                  v-bind:key="st.id">
                            {{ st.status }}
                          </option>
                        </b-form-select>
                      </b-form-group>

                      <b-form-group
                          :label="msg('Branch')"
                          label-for="branchId"
                          label-cols="6"
                          class="mb-1">

                        <b-form-select id="branchId" size="sm"
                                       v-model="bulkChange.branchId">
                          <option value="0">{{ msg('Do not update') }}</option>
                          <option v-for="bu in getAllBranches"
                                  v-bind:value="bu.id" v-bind:key="bu.id">
                            {{ bu.name }}
                          </option>
                        </b-form-select>
                      </b-form-group>

                      <button class="btn btn-primary btn-block" @click="bulkChangeAction">{{ msg('Update') }}
                      </button>

                    </form>
                  </div>
                </b-popover>
                <!--Bulk change popover-->
                <b-popover :show.sync="operations.addRepresentative" @show="dismissPopover"
                           size="xl"
                           @hide="resetOperations" target="addRepresentative"
                           placement="bottom" :title="msg('Add representant')">
                  <div class="p-2" style="min-width: 350px">
                    <form @submit.prevent="bulkAddRepresentative">

                      <b-form-group
                          :label="msg('Representative')"
                          label-for="repId"
                          label-cols="6"
                          class="mb-1"
                      >
                        <b-form-select size="sm" v-model="bulkChange.addRepId">
                          <option value="0">{{ msg('Do not update') }}</option>
                          <option v-for="us in getAllUsers" v-bind:value="us.id"
                                  v-bind:key="us.id">
                            {{ us.firstname }} {{ us.lastname }}
                          </option>
                        </b-form-select>
                      </b-form-group>

                      <b-form-group
                          :label="msg('Limited time')"
                          label-for="repId"
                          label-cols="6"
                          class="mb-1"
                      >
                        <b-form-checkbox
                            v-model="bulkChange.addRepCustomDates"
                            class="custom-control-primary"
                            name="check-button"
                            switch
                        />
                      </b-form-group>

                      <b-form-group v-if="bulkChange.addRepCustomDates === true"
                                    :label="msg('Start date')" label-for="repId"
                                    label-cols="6" class="mb-1">
                        <VueCtkDateTimePicker v-model="bulkChange.addRepFrom"
                                              format="YYYY-MM-DD HH:mm"
                                              formatted="ll" no-header auto-close
                                              :min-date="dateNow">
                          <b-form-input size="sm" v-model="addRepFromFormated"/>
                        </VueCtkDateTimePicker>
                      </b-form-group>

                      <b-form-group :label="msg('End date')" label-for="repId"
                                    label-cols="6" class="mb-1"
                                    v-if="bulkChange.addRepCustomDates === true">
                        <VueCtkDateTimePicker v-model="bulkChange.addRepUntil"
                                              format="YYYY-MM-DD HH:mm"
                                              formatted="ll" no-header auto-close
                                              :min-date="dateNow">
                          <b-form-input size="sm" v-model="addRepUntilFormated"/>
                        </VueCtkDateTimePicker>
                      </b-form-group>
                      <button class="btn btn-primary btn-block"
                              :disabled="bulkChange.addRepId<=0">{{ msg('Update') }}
                      </button>

                    </form>
                  </div>
                </b-popover>
                <!--Bulk change popover-->
                <b-popover :show.sync="operations.duplicateClients" @show="dismissPopover"
                           size="xl"
                           @hide="resetOperations" target="duplicateClients"
                           placement="bottom" :title="msg('Duplicate client')">
                  <div style="min-width: 250px">
                    <b-form-group :label="msg('Branch')" label-for="client-branch">
                      <v-select
                          v-bind:dir="isRTL ? 'rtl' : 'ltr'"
                          v-model="bulkChange.branchId"
                          :options="Object.values(getAllBranches)"
                          class="w-100"
                          label="name"
                          :reduce="(branch) => branch.id"
                          :clearable="false"
                      />
                    </b-form-group>
                    <b-button variant="primary" block @click="duplicateClients">{{ msg("Duplicate") }}</b-button>
                  </div>

                </b-popover>
              </th>
            </template>
          </tr>
          </thead>
          <tbody>
          <!--Table-->
          <template v-if="isLoaded">
            <tr v-for="client in allClients" v-bind:key="client.id">
              <td class="d-none d-lg-table-cell" v-if="canOperateClients&& canOperateRep">
                <b-form-checkbox v-model="selectedRows" :value="client.id"></b-form-checkbox>
              </td>
              <!--id,creationTime,branchId,firstname,lastname,representativeId,repFullname,phone,mobile,country,city,address,zip,email,email2,statusid,bannerid,lang,comment,tag,birthday-->
              <td class="d-none d-lg-table-cell" v-if="activeColumns['id']" @click="onClientSelect(client)">
                {{ client.id }}
              </td>
              <td class="d-none d-lg-table-cell" v-if="activeColumns['count']" @click="onClientSelect(client)">
                {{ client.clientExt!=null?client.clientExt.numberOfBookings:0 }}
              </td>
              <td class="d-none d-lg-table-cell" v-if="activeColumns['creationtime']" @click="onClientSelect(client)">
                {{ client.interesttime | moment('DD/MM/YY HH:mm') }}
              </td>
              <td class="d-none d-lg-table-cell" v-if="activeColumns['branchId']" @click="onClientSelect(client)">
                {{ getBranchName(client.branchId) }}
              </td>
              <td class="d-none d-lg-table-cell" v-if="activeColumns['verified']" @click="onClientSelect(client)">
                <b-badge pill variant="success"
                         v-if="(client.clientExt!=null && client.clientExt.verified!=null&&client.clientExt.verified)">
                  {{ msg('Yes') }}
                </b-badge>
                <b-badge v-else variant="danger"> {{ msg('No') }}
                </b-badge>
              </td>
              <td class="d-none d-lg-table-cell" v-if="activeColumns['firstname']" @click="onClientSelect(client)">
                {{ client.firstname }}
              </td>
              <td class="d-none d-lg-table-cell" v-if="activeColumns['lastname']" @click="onClientSelect(client)">
                {{ client.lastname }}
              </td>
              <td class="d-table-cell d-lg-none" v-if="activeColumns['lastname']" @click="onClientSelect(client)">
                {{ client.firstname }} {{ client.lastname }}
              </td>
              <td class="d-none d-lg-table-cell" v-if="activeColumns['phone']" @click="onClientSelect(client)">
                {{ client.phone }}
              </td>
              <td class="d-none d-lg-table-cell" v-if="activeColumns['mobile']" @click="onClientSelect(client)">
                {{ client.mobile }}
              </td>
              <td class="d-none d-lg-table-cell" v-if="activeColumns['email']" @click="onClientSelect(client)">
                {{ client.email }}
              </td>
              <td class="d-none d-lg-table-cell" v-if="activeColumns['address']" @click="onClientSelect(address)">
                {{ client.address }}
              </td>
              <td class="d-none d-lg-table-cell" v-if="activeColumns['zip']" @click="onClientSelect(client)">
                {{ client.zip }}
              </td>
              <td class="d-none d-lg-table-cell" v-if="activeColumns['tag']" @click="onClientSelect(client)">
                {{ client.tag }}
              </td>
              <td class="d-none d-lg-table-cell" v-if="activeColumns['country']" @click="onClientSelect(client)">
                {{ getCityAndCountry(client) }}
              </td>
              <td class="d-none d-lg-table-cell client-status" v-if="activeColumns['statusid']"
                  @dblclick="editClientStatus = client.id">

                <div v-if="editClientStatus !== client.id"
                     v-bind:style="[ getStatusColor(client.statusid) != null ? {'color':`${getStatusColor(client.statusid)}`}:{}]">
                  <i :class="{[getStatusIcon(client.statusid)] : true}"></i>
                  {{ getStatus(client.statusid).status }}
                  <p style="color:black;">{{ client.statustime | moment('DD/MM/YY HH:mm') }}</p>
                </div>
                <div v-else>
                  <select class="form-control" @blur="editClientStatus = 0"
                          style="cursor: pointer;padding: 0 0 0 5px;min-height: auto;height: 22px;"
                          v-model="client.statusid" @change="updateStatus(client,client.statusid)">
                    <option value="-1">{{ msg('no status') }}</option>
                    <option v-for="(s,id) in getAllStatus" v-bind:value="id" v-bind:key="id">
                      {{ s.status }}
                    </option>
                  </select>
                </div>
              </td>
              <td class="d-table-cell d-lg-none" v-if="activeColumns['statusid']"
                  v-bind:style="[ getStatusColor(client.statusid) != null ? {'color':`${getStatusColor(client.statusid)}`}:{}]">
                <i :class="{[getStatusIcon(client.statusid)] : true}"></i>
                {{ getStatus(client.statusid).status }}
              </td>
            </tr>
          </template>
          <!--Loading-->
          <template v-else>
            <tr class="table-loading">
              <td class="d-none d-lg-table-cell" colspan="11">
                <p>
                  <b-spinner label="Spinning"/>
                  {{ msg('loading') }}
                </p>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>

      <!--Table Paginator-->
      <div class="paginator text-center" v-if="paginator !== false">
        <!--Page number-->
        <div class="text-right" style="display: inline-block">
          ({{ currentPageNumber }} of {{ page.total }})
        </div>
        <!--Paginator-->
        <div class="ml-2" style="display: inline-block ">
          <b-pagination
              :disabled="!isLoaded"
              limit="11"
              :per-page="page.amountPerPage"
              :current-page="page.number"
              :total-rows="page.total"
              align="center"
              @input="onPageChange"
          />
        </div>
        <!--Amount of rows per page-->
        <div class="ml-2" style="display: inline-block" v-if="this.amountPerPage == null || this.amountPerPage <1">
          <select class="form-control" v-model="page.amountPerPage" style="display: inline-block"
                  @change="filterUpdate">
            <option v-for="i in 100 " v-bind:value="i" v-bind:key="i">
              {{ i }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center">
        <b-spinner label="Spinning"/>
        {{ msg('loading') }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'clientTable',
  props: {
    filter: Object,
    columns: Object,
    specificCols: {
      default: false,
      type: Boolean,
    },
    clientOperations: {
      default: false,
      type: Boolean,
    },
    canOperate: {
      default: true,
      type: Boolean,
    },
    isOwner: {
      default: null,
      type: Boolean,
    },
    isRenter: {
      default: null,
      type: Boolean,
    },
    paginator: {
      default: true,
      type: Boolean,
    },
    isLead: {
      default: false,
      type: Boolean,
    },
    amountPerPage: {
      default: null,
      type: Number,
    },
    deleteStatus: {
      default: null,
      type: Number,
    },
  },
  data: () => ({
    // Table

    loaded: false,
    error: '',

    clients: [],
    page: {
      amountPerPage: 50,
      total: 1,
      number: 1,
    },

    tableSort: {
      sortby: 'desc',
      sortcolumn: 'id',
    },

    //For multi selection box
    allRowsSelected: false,
    indeterminateRows: false,
    selectedRows: [],
    selectedRowsClients: [],
    bulkChange: {
      statusId: 0,
      branchId: 0,
      repId: 0,
      rep2Id: 0,
      addRepId: 0,
      addRepCustomDates: false,
      addRepFrom: null,
      addRepUntil: null
    },
    operations: {
      bulkChange: false,
      addRepresentative: false,
      duplicateClients: false
    },

    //Edit status inline
    editClientStatus: 0,
    //Listeners
    listeners: {
      refresh: () => {
      }
    }
  }),
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    tableSort: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    amountPerPage: {
      handler() {
        if (this.amountPerPage != null) {
          this.page.amountPerPage = this.amountPerPage
          this.refresh()
        }
      },
      immediate: true
    },
    selectedRows() {

      let $this = this

      this.selectedRowsClients = [
        //Filter selectedRowsClients [in case that clients doesnt exist ay more]
        ...$this.selectedRowsClients.filter(client => $this.selectedRows.includes(client.id)),
        //Get clients from cache
        ...$this.clients.filter(client => $this.selectedRows.includes(client.id) && $this.selectedRowsClients.findIndex(cacheClient => cacheClient.id === client.id) < 0)
      ]
      console.log('clientsTable:watch->selectedRows()', {selectedRowsClients: $this.selectedRowsClients})
      this.$emit('update:multipleClients', this.selectedRowsClients)
      if (this.selectedRows.length === 0) {
        this.allRowsSelected = false
        this.indeterminateRows = false
        this.resetOperations()
        return
      }
      if (this.selectedRows.length >= this.allClients.length) {
        this.allRowsSelected = true
        this.indeterminateRows = false
        return
      }
      this.indeterminateRows = true
      this.dismissPopover()

    },
    allRowsSelected() {
      this.selectedRows = []
      if (this.allRowsSelected !== true) {
        return
      }

      this.allClients.forEach(c => this.selectedRows.push(c.id))
      this.dismissPopover()
    }

  },
  computed: {
    ...mapGetters('translator', ['msg', 'currentFormattedLang']),
    ...mapGetters('data', {
      _getCountry: 'getCountry',
      fieldActive: 'clientTableFieldActive',
    }),
    ...mapGetters('data', ['getOtherUsers', 'getUsersNotByRole', 'getUsersByRole', 'getUserFullName', 'getUserColor', 'getUserAcronyms', 'getAllLanguage', 'getAllCountries', 'getAllUsers', 'getAllBranches', 'getBranchName', 'isEnergyOrg', 'isCompanyClients']),
    ...mapGetters('clients', ['rep3Alias', 'isDataLoaded', 'canEditRep', 'canOperateRep', 'getStatus', 'getAllStatus', 'getColumns', 'hasTag', 'hasStatus', 'roleInstalIs', 'hasAvatar']),

    isLoaded() {
      return this.loaded === true
    },
    trashColor() {
      return this.deleteStatus == 0 ? 'red' : 'green'

    },
    allClients() {
      if (!Array.isArray(this.clients)) {
        return []
      }
      return this.clients
    },
    pageNumber() {
      if (this.page.number <= 0) {
        return 1
      }

      return this.page.number
    },
    optionsEnable() {
      return this.canOperateClients && this.canOperate !== false && this.selectedRows.length > 0
    },
    currentPageNumber() {
      return ((this.pageNumber - 1) * this.page.amountPerPage) + 1
    },
    addRepFromFormated: {
      get() {
        return this.$moment(this.bulkChange.addRepFrom)
            .format('DD/MM/YYYY HH:mm')
        // return this.$moment(this.event.date).format('dddd, DD MMMM YYYY HH:mm');
        // return date.dateStringFormated(this.event.date, true);
      },
      set(value) {
        if (this.$moment(value, 'DD/MM/YYYY  HH:mm', true)
            .isValid()) {
          this.bulkChange.addRepFrom = this.$moment(value, 'DD/MM/YYYY HH:mm')
              .format('YYYY-MM-DD HH:mm')
        }
      }
    },
    addRepUntilFormated: {
      get() {
        if (this.$moment(this.bulkChange.addRepUntil, 'YYYY-MM-DD HH:mm', true)
            .isValid()) {
          return this.$moment(this.bulkChange.addRepUntil)
              .format('DD/MM/YYYY HH:mm')
        }
        return ''
      },
      set(value) {
        if (this.$moment(value, 'DD/MM/YYYY  HH:mm', true)
            .isValid()) {
          this.bulkChange.addRepUntil = this.$moment(value, 'DD/MM/YYYY HH:mm')
              .format('YYYY-MM-DD HH:mm')
        }
      }
    },
    activeColumns() {
      let cols = {}
      if (this.specificCols !== true) {
        Object.assign(cols, {
          id: true || this.getColumns['id'] == true && this.fieldActive('id'),
          count: true || this.getColumns['count'] == true && this.fieldActive('count'),
          branchId: true || this.getColumns['branchId'] == true && this.fieldActive('branchId'),
          verified: true || this.getColumns['verified'] == true && this.fieldActive('verified'),
          assignationtime: true || this.getColumns['assignationtime'] == true && this.fieldActive('assignationtime'),
          interesttime: true || this.getColumns['interesttime'] == true && this.fieldActive('interesttime'),
          creationtime: true || this.getColumns['interesttime'] == true && this.fieldActive('interesttime'),
          siret: true || this.getColumns['siret'] == true && this.isCompanyClients,
          companyName: true || this.getColumns['companyName'] == true && this.isCompanyClients,
          firstname: true || this.getColumns['firstname'] == true && this.fieldActive('firstname'),
          lastname: true || this.getColumns['lastname'] == true && this.fieldActive('lastname'),
          phone: true || this.getColumns['phone'] == true && this.fieldActive('phone'),
          mobile: true || this.getColumns['mobile'] == true && this.fieldActive('mobile'),
          address: true || this.getColumns['address'] == true && this.fieldActive('address'),
          email: true || this.getColumns['email'] == true && !this.isCompanyClients && this.fieldActive('email'),
          country: true || this.getColumns['country'] == true && this.fieldActive('country'),
          zip: true || this.getColumns['zip'] == true && this.fieldActive('zip'),
          tag: true || this.getColumns['tag'] == true && this.fieldActive('tag'),
          statusid: this.getColumns['statusid'] == true && this.hasStatus,
        })
      }

      if (typeof this.columns === 'object') {
        let nonAccessCols = Object.values(cols)
            .filter(c => c === true)
            .reduce((obj, type) => Object.assign(obj, {[type]: []}), {})
        Object.assign(cols, this.columns)
        Object.assign(cols, nonAccessCols)
      }
      return cols
    },
    canOperateClients() {
      return this.clientOperations === true
    }
  },
  created() {
    let $this = this
    let date = new Date()
    this.bulkChange.addRepFrom = this.$moment(date)
        .format('YYYY-MM-DD HH:mm')
    date.setDate(date.getDate() + 1)
    this.bulkChange.addRepUntil = this.$moment(date)
        .format('YYYY-MM-DD HH:mm')

    this.loadData()
        .then(() => {
          $this.refresh()
        }, function (e) {
          $this.error = e
        })

    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      console.log('clientTable->event(crm::branch-updated)->refresh()')
      this.refresh()
    }))
    this.$root.$on('crm::client-table::refresh', this.listeners.refresh)
    this.$root.$on('crm::event::updated', this.listeners.refresh)
  },
  beforeDestroy() {
    this.loaded = false
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
    this.$root.$off('crm::client-table::refresh', this.listeners.refresh)
    this.$root.$off('crm::event::updated', this.listeners.refresh)
  },
  methods: {
    ...mapActions('clients', {_updateStatus: 'updateStatus'}),
    ...mapActions('clients', ['loadData', 'change']),
    ...mapActions('clients', {
      _bulkChange: 'bulkChange',
      _duplicateClients: 'duplicate',
      _deleteClients: 'deleteClients',
      _bulkAddRepresentative: 'bulkAddRepresentative',
      _refresh: 'getClients',
      _update2: 'update'
    }),
    refresh() {
      console.log('clientTable->refresh()')
      let $this = this
      $this.loaded = false

      for (var prop in this.filter) {
        if (Object.prototype.hasOwnProperty.call(this.filter, prop)) {
          // do stuff
          // console.log("print filter from client",prop)
          if (this.filter[prop] === '') {
            this.filter[prop] = null
          }
        }
      }
      if (this.isOwner!=null){
        this.filter.clientExt={};
        this.filter.clientExt.owner=true;
      }

      if (this.isRenter!=null){
        this.filter.clientExt={};
        this.filter.clientExt.renter=true;
      }

      this._refresh({
        page: $this.page.number,
        amountPerPage: $this.page.amountPerPage,
        filter: this.filter,
        sortby: this.tableSort.sortby,
        sortcolumn: this.tableSort.sortcolumn
      })
          .then((data) => {
            $this.clients = $this.filterThroughClientExtData(data['clients'])
            $this.page = data['page']
            $this.loaded = true
          }, (e) => $this.error = e)
    },
    filterThroughClientExtData(clients) {
      if (this.isOwner != null && this.isOwner === true)
        return clients.filter(client => client.clientExt != null && client.clientExt.owner)
      else if (this.isRenter != null && this.isRenter === true)
        return clients.filter(client => client.clientExt != null && client.clientExt.renter)

      else return clients;

    },
    updateStatus(client, statusid) {
      let $this = this
      this._updateStatus({
        id: client.id,
        clientStatus: statusid
      })
          .then(() => {
            //TODO: GET FROM SERVER
            client.statustime = $this.$moment(Date.now())
                .format('YYYY-MM-DD HH:mm')
          })
    },
    dismissPopover() {
      this.$root.$emit('bv::hide::popover')
      Object.keys(this.operations)
          .forEach((k) => this.operations[k] = false)
    },
    getCityAndCountry(client) {
      if (client.city != null && client.city != '' && client.country != null && client.country > 0) {
        return client.city + ',' + this._getCountry(client.country).name
      }
      if (client.city != null && client.city != '') {
        return client.city
      }

      return this._getCountry(client.country).name
    },
    getClientStyle(clientStatusId) {
      let color = this.getStatusColor(clientStatusId)
      let bkColor = this.getStatusBkColor(clientStatusId)
      let ans = {}
      if (color != null) {
        ans['color'] = color
      }
      if (bkColor != null) {
        ans['background-color'] = color
      }
      return ans
    },
    getStatusColor(statusId) {
      let status = this.getStatus(statusId)
      if (status == null || status.color == null || status.color.trim() == '') {
        return null
      }

      return status.color
    },
    getStatusIcon(statusId) {
      let status = this.getStatus(statusId)
      if (status == null || status.icon == null || status.icon.trim() == '') {
        return null
      }

      return status.icon
    },
    getStatusBkColor(statusId) {
      let status = this.getStatus(statusId)
      if (status == null || status.bkColor == null || status.bkColor.trim() == '') {
        return null
      }

      return status.bkColor
    },
    onPageChange(val) {
      this.selectedRows = []
      this.page.number = val
      this.refresh()
    },
    filterUpdate() {
      this.selectedRows = []
      this.refresh()

    },
    sort(col) {
      if (this.tableSort.sortcolumn !== col) {
        this.tableSort.sortby = 'asc'
        this.tableSort.sortcolumn = col
      } else {
        this.tableSort.sortby = this.tableSort.sortby === 'asc' ? 'desc' : 'asc'
      }
      //this.tableSort = {...this.tableSort};
    },
    sortStatus(col) {
      if (this.tableSort.sortcolumn !== col) {
        return 'none'
      }
      return this.tableSort.sortby === 'asc' ? 'ascending' : 'descending'
    },
    onClientSelect(client) {
      // this.$root.$emit('crm::client::select', client.id)
      this.$emit('clientSelect', client)
    },
    bulkChangeAction() {

      let $this = this
      this._bulkChange({
        clientsId: this.selectedRows,
        statusId: this.bulkChange.statusId,
        branchId: this.bulkChange.branchId,
      })
          .then(() => {
            $this.selectedRows = []
            $this.resetOperations()
            $this.refresh()
          }, () => {
          })
    },
    deleteClients() {

      let $this = this
      this._deleteClients({
        clientsId: this.selectedRows,
        status: this.deleteStatus,
      })
          .then(() => {
            $this.selectedRows = []
            $this.resetOperations()
            $this.refresh()
          }, () => {
          })

    },
    resetOperations() {
      //bulkChange
      this.operations.bulkChange = false
      this.bulkChange.statusId = 0
      this.bulkChange.branchId = 0
      this.bulkChange.repId = 0
      this.bulkChange.rep2Id = 0
      this.bulkChange.addRepId = 0
      this.bulkChange.addRepCustomDates = false
      let date = new Date()
      this.bulkChange.addRepFrom = this.$moment(date)
          .format('YYYY-MM-DD HH:mm')
      date.setDate(date.getDate() + 1)
      this.bulkChange.addRepUntil = this.$moment(date)
          .format('YYYY-MM-DD HH:mm')
    },
    bulkAddRepresentative() {

      let $this = this
      let from = null
      let until = null

      if (this.bulkChange.addRepCustomDates == true && (this.$moment(this.bulkChange.addRepFrom, 'YYYY-MM-DD HH:mm', true)
          .isValid())) {
        from = this.bulkChange.addRepFrom
      }

      if (this.bulkChange.addRepCustomDates == true && (this.$moment(this.bulkChange.addRepUntil, 'YYYY-MM-DD HH:mm', true)
          .isValid())) {
        until = this.bulkChange.addRepUntil
      }

      this._bulkAddRepresentative({
        clientsId: this.selectedRows,
        repId: this.bulkChange.addRepId,
        from,
        until
      })
          .then(() => {
            $this.selectedRows = []
            $this.resetOperations()
            $this.refresh()
          }, () => {
          })
    },
    duplicateClients() {
      let $this = this
      this._duplicateClients({clientsId: $this.selectedRows, branchId: $this.bulkChange.branchId,})
          .finally(() => {
            $this.selectedRows = []
            $this.resetOperations()
            $this.refresh()
          }, () => {
          })
    }
  }
}
</script>

<style>
td.client-status {
  font-weight: bold;
  font-size: 17px;
  vertical-align: middle;
  padding-bottom: 0;
  padding-top: 2px;
}

td.client-status > div {
  line-height: 15px;
  font-size: 20px;
}

td.client-status > div > p {
  font-size: 11px;
  color: #ccc;
  margin: 0;
  padding: 0;
  text-align: center;
}
</style>
